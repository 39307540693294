import { Alert, Grid, GridItem, Text } from "@chakra-ui/react"
import { PlaysT } from "app/commonUtils"
import { PlayableAlbum } from "app/core/components/Track"

export const AlbumGrid = ({
  plays,
  highlightedPlayId,
}: {
  plays: PlaysT
  highlightedPlayId: number | null
}) => {
  if (plays.length === 0) {
    return (
      <Alert variant="left-accent" flex={1}>
        No music found.
      </Alert>
    )
  } else if (plays.length < 4) {
    const track = plays[0]!.spotifyBlob
    return <PlayableAlbum index={0} tracks={[track]} flex={1} />
  } else {
    const tracks = plays.map((play) => play.spotifyBlob)
    const fourPlays = plays.slice(0, 4)
    return (
      <Grid templateColumns="repeat(2, 1fr)" gap={0} flex={1}>
        {fourPlays.map((play, index) => {
          return (
            <GridItem key={play.id} bg="blue.500" position="relative">
              {highlightedPlayId === play.id && (
                <Text position="absolute" fontSize={[16, 24]} zIndex={5} left={1} opacity={0.8}>
                  🔥
                </Text>
              )}
              <PlayableAlbum index={index} tracks={tracks} />
            </GridItem>
          )
        })}
      </Grid>
    )
  }
}
