import { Box, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"

export const FireBox = styled(Box)`
  @keyframes animateBg {
    0% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  animation: animateBg 8s linear infinite;
  background-image: linear-gradient(80deg, #f6cb66, #fcfabf, #e1430e, #f6cb66, #fcfabf);
  background-size: 400% 100%;
`
export const WiggleText = styled(Text)`
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(0deg);
    }
    35% {
      transform: rotate(10deg);
    }
    45% {
      transform: rotate(-10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  animation: wiggle 2.5s;
  animation-iteration-count: infinite;
`
