import { useMutation } from "@blitzjs/rpc"
import {
  Box,
  BoxProps,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react"
import { hasPlaylistScopes, timeDiffString } from "app/commonUtils"
import createPlaylist from "app/users/mutations/createPlaylist"
import setLikeTrack, {
  Liked,
  LikeTrackMetadata,
  SetLikeTrackResponse,
  Unliked,
} from "app/users/mutations/setLikeTrack"
import setHighlightMutation from "app/users/mutations/setHighlight"
import { MinimalTrack, SerializedActivity } from "app/users/queries/getActivity"
import { useRouter } from "next/router"
import { ActivityContext } from "app/core/ActivityContext"
import { PlayerContext, UserContext } from "pages/_app"
import { useContext } from "react"
import { IoHeart, IoHeartOutline, IoPause, IoPlay } from "react-icons/io5"
import { accountsFromUser } from "./AuthButtons"

export const Plays = ({
  activity,
  small,
  ...rest
}: {
  activity: SerializedActivity
  small: boolean
} & BoxProps) => {
  const { user } = useContext(UserContext)
  const { strava } = accountsFromUser(user)
  const { start, id: activityId, trackPlays: plays, highlightedPlay } = activity
  const canPickHighlight = !!strava && activity.accountId === strava.id && highlightedPlay === null

  const tracks = plays.map((play) => play.spotifyBlob)
  return (
    <Box {...rest}>
      {plays.map((play, index) => {
        return (
          <Box padding={0} key={play.id}>
            <Track
              playId={play.id}
              tracks={tracks}
              index={index}
              small={small}
              trackMetadata={{
                context: "activity",
                activityId,
                playId: play.id,
              }}
              subtitle={
                (play.playedAt && start && timeDiffString(play.playedAt, start)) || undefined
              }
              canPickHighlight={canPickHighlight}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export const TopTrackList = ({
  tracks: tracksWithCount,
  small,
  metadata,
  ...rest
}: {
  tracks: [MinimalTrack, number][]
  small: boolean
  metadata: LikeTrackMetadata
} & BoxProps) => {
  const background = useColorModeValue("blue.50", "blue.800")
  const tracks = tracksWithCount.map(([track]) => track)

  return (
    <Box {...rest}>
      {tracksWithCount.map(([track, count], index) => {
        if (!track) {
          return null
        }
        return (
          <Box
            padding={0}
            key={track.id}
            _hover={{
              cursor: "pointer",
              background,
            }}
          >
            <Track
              tracks={tracks}
              index={index}
              small={small}
              trackMetadata={metadata}
              subtitle={count.toString() + " plays"}
            />
          </Box>
        )
      })}
    </Box>
  )
}

function isLike(response: SetLikeTrackResponse): response is Liked {
  return (response as Liked).isNew !== undefined
}

function isUnlike(response: SetLikeTrackResponse): response is Unliked {
  return (response as Unliked).wasPresent !== undefined
}

const Track = ({
  tracks,
  index,
  small,
  trackMetadata,
  subtitle,
  canPickHighlight = false,
  playId,
}: {
  tracks: MinimalTrack[]
  index: number
  small: boolean
  trackMetadata: LikeTrackMetadata
  subtitle?: string
  canPickHighlight?: boolean
  playId?: number
}) => {
  const activityContext = useContext(ActivityContext)
  const router = useRouter()
  const { user, refetch: refetchUser } = useContext(UserContext)
  const playerContext = useContext(PlayerContext)
  const [likeTrack, { isLoading: isLoadingLike }] = useMutation(setLikeTrack)
  const [setHighlight, { isLoading: isSettingHighlight }] = useMutation(setHighlightMutation)
  const [createList, { isLoading: isLoadingPlaylist }] = useMutation(createPlaylist)
  const toast = useToast({
    colorScheme: "green",
    duration: 2000,
  })

  const track = tracks[index]!
  const liked = track && user?.playlist?.trackIds?.includes(track.id)
  const { spotify } = accountsFromUser(user)

  const like = async (isLiked: boolean) => {
    try {
      if (!spotify) {
        void router.push("/api/auth/spotify?redirectUrl=" + router.asPath)
      } else if (!hasPlaylistScopes(spotify.scopes)) {
        playerContext?.setPlaylistModalMode("scope")
      } else {
        if (!user?.playlist) {
          // setPlaylistModalMode("create")
          await createList({})
          toast({
            title: 'Created "Liked on Spava" on Spotify.',
            description: "Click the You button to open it.",
            duration: 8000,
          })
        }

        const result = await likeTrack({ trackId: track.id, like: isLiked, ...trackMetadata })

        if (isUnlike(result)) {
          if (result.wasPresent) {
            toast({ title: `Removed "${track.name}" from Liked on Spava`, status: "success" })
          }
        } else if (isLike(result)) {
          if (result.isNew) {
            toast({ title: `Added "${track.name}" to Liked on Spava`, status: "success" })
          } else {
            toast({ title: `Already in the playlist`, status: "success", duration: 2000 })
          }
        } else {
          toast({ title: `Error`, status: "error" })
          return // don't refetch user
        }
        await refetchUser()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const isLoading = isLoadingLike || isLoadingPlaylist

  if (!track) {
    return (
      <div>
        <p>Track not found</p>
        <p>Index: {index}</p>
      </div>
    )
  }

  return (
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns={small ? "repeat(6, 1fr)" : "repeat(5, 1fr)"}
      gap={2}
    >
      <GridItem colSpan={1} rowSpan={1}>
        <PlayableAlbum tracks={tracks} index={index} />
      </GridItem>
      <GridItem colSpan={small ? (canPickHighlight ? 3 : 4) : 3} rowSpan={1}>
        {/* <a href={track.external_urls.spotify} target="_blank" rel="noreferrer"> */}
        {subtitle && (
          <Text fontSize={{ base: 11, sm: "xs" }} lineHeight="1em" mt={[1, 2]} opacity="0.9">
            {subtitle}
          </Text>
        )}
        <Heading
          as="h2"
          fontSize={small ? { sm: "lg", base: "sm" } : { sm: "xl", base: "md" }}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          lineHeight="1.5em"
          fontWeight="semibold"
        >
          {track.name}
        </Heading>
        <Text
          opacity="0.7"
          // color="gray"
          fontWeight="medium"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          lineHeight="1.2em"
          fontSize={small ? { sm: "sm", base: "xs" } : { sm: "lg", base: "sm" }}
        >
          {track.artists.map((a) => a.name).join(", ")}
        </Text>
        {/* </a> */}
      </GridItem>
      <GridItem
        colSpan={1}
        rowSpan={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize={40}
        opacity={0.8}
        _hover={{ opacity: 1 }}
        onClick={() => like(!liked)}
        id="ic-like-button-box"
        cursor="pointer"
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Icon fontSize={[22, 28]} as={liked ? IoHeart : IoHeartOutline} />
        )}
        {/* </Box> */}
      </GridItem>
      {canPickHighlight && playId && activityContext?.activity && (
        <GridItem
          colSpan={1}
          rowSpan={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize={40}
          opacity={0.8}
          _hover={{ opacity: 1 }}
          onClick={async () => {
            await setHighlight({ set: "set", playId, activityId: activityContext.activity.id })
            await activityContext?.refetch()
            toast({
              title: "Highlight set!",
              description: track.name,
              status: "success",
              duration: 5000,
            })
            setTimeout(() => {
              window.location.hash = "#highlighted"
            }, 500)
          }}
          id="ic-like-button-box"
          cursor="pointer"
        >
          {isSettingHighlight ? (
            <Spinner />
          ) : (
            <Text textAlign="center" fontSize={[28, 40]}>
              🔥
            </Text>
          )}
        </GridItem>
      )}
    </Grid>
  )
}

export const PlayableAlbum = ({
  tracks,
  index,
  ...rest
}: { tracks: MinimalTrack[]; index: number } & BoxProps) => {
  const track = tracks[index]
  const player = useContext(PlayerContext)
  const thisTrack =
    !!track?.preview_url && player?.url === track?.preview_url && player.state !== "paused"

  return (
    <Box
      onClick={() =>
        track && track.preview_url && player?.play(tracks.slice(index).map((t) => t.preview_url))
      }
      pos="relative"
      cursor="pointer"
      {...rest}
    >
      <Image src={track?.album.images[1]?.url} alt={`Album art for ${track?.album.name}`} />
      <Box
        opacity={thisTrack ? 1 : 0.8}
        _hover={{ opacity: thisTrack ? 0.8 : 1 }}
        w="full"
        h="full"
        position="absolute"
        top="0"
        zIndex={100}
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize={[30, 40]}
        color="gray.200"
        textShadow="red 2px 5px;"
      >
        {thisTrack ? player.state === "loading" ? <Spinner /> : <IoPause /> : <IoPlay />}
      </Box>
    </Box>
  )
}

export default Track
