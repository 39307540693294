import { SerializedActivity } from "app/users/queries/getActivity"
import { createContext } from "react"
import { RefetchMethod } from "pages/_app"

export type ActivityContextT = {
  activity: SerializedActivity
  refetch: RefetchMethod
}

export const ActivityContext = createContext<ActivityContextT | null>(null)
