import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import {
  defaultProfileImage,
  elapsedTimeStringWithLetters,
  getMapImage,
  timeDiffString,
} from "app/commonUtils"
import Track, { Plays } from "app/core/components/Track"
import { SerializedActivity } from "app/users/queries/getActivity"
import Link from "next/link"
import { IoStopwatchOutline, IoClose } from "react-icons/io5"
import { AlbumGrid } from "./AlbumGrid"
import { FireBox, WiggleText } from "./FireBox"
import setHighlightMutation from "app/users/mutations/setHighlight"
import { useMutation } from "@blitzjs/rpc"
import { useContext } from "react"
import { ActivityContext } from "app/core/ActivityContext"
import { accountsFromUser } from "./AuthButtons"
import { UserContext } from "pages/_app"

export const ActivityItem = ({
  activity,
  short = false,
}: {
  activity: SerializedActivity
  short?: boolean
}) => {
  const userContext = useContext(UserContext)
  const { strava } = accountsFromUser(userContext.user)
  const ownsActivity = activity.accountId === strava?.id
  const activityContext = useContext(ActivityContext)
  const { id, trackPlays: plays, start, user, highlightedPlay, highlightedPlayId } = activity
  const data = activity.data as any

  const highlightedPlayInFeed = plays.find((p) => p.id === highlightedPlayId)
  const playsToShow = (highlightedPlayInFeed ? [highlightedPlayInFeed] : []).concat(
    plays.filter((p) => p.id !== highlightedPlayId)
  )
  // .slice(0, short ? (plays.length < 4 ? 1 : 4) : plays.length)
  const mapUrl = getMapImage(activity, short)
  const elapsedTime = (activity.data as any)?.elapsed_time || null
  const now = new Date()
  const [setHighlight, { isLoading: isSettingHighlight }] = useMutation(setHighlightMutation)

  const doOverlay = !short && !!mapUrl
  const thenBackground = useColorModeValue(
    "linear-gradient(to bottom, rgba(255,255,255,0.6) 60%, rgba(255,255,255,0))",
    "linear-gradient(to bottom, rgba(26,32,44,0.8) 0%, rgba(26,32,44,0.6) 40%, rgba(255,255,255,0))"
  )
  // const trackHoverBackground = useColorModeValue("blue.50", "blue.800")
  const background = useColorModeValue("rgba(255,255,255,0.6)", "rgba(26,32,44,0.8)")

  return (
    <Box position="relative">
      <Box
        pt={3}
        pb={doOverlay ? 10 : 2}
        px={doOverlay ? 3 : 3}
        top="0"
        position={doOverlay ? "absolute" : "relative"}
        bg={short ? undefined : thenBackground}
        w="full"
      >
        <Box display="flex">
          {user?.picture && (
            <Image
              src={user?.picture || defaultProfileImage}
              borderRadius="full"
              alt={`profile image of ${user.name}`}
              height={12}
              width={12}
              objectFit="cover"
              overflow="hidden"
              mr={2}
            />
          )}
          <Box flex="1">
            <HStack spacing={1} lineHeight="1em" fontSize={short ? "xs" : "sm"}>
              {user?.name && user.stravaId && (
                <>
                  {/* <a
                    href={short ? "" : `https://www.strava.com/athletes/${user?.stravaId}`}
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <Text fontWeight="medium">{user.name}</Text>
                  {/* </a> */}
                </>
              )}
              <Text fontWeight="regular" opacity={0.5}>
                {elapsedTimeStringWithLetters(elapsedTime, false)}{" "}
                {start.toLocaleDateString() === now.toLocaleDateString()
                  ? "Today"
                  : "on " + start.toLocaleDateString().replace("/" + now.getFullYear(), "")}{" "}
              </Text>
            </HStack>
            <Link href={`/a/${id}`}>
              <a>
                <Text
                  fontSize={short ? ["1em", "1.3em"] : ["1.3em", "1.6em"]}
                  fontWeight="bolder"
                  wordBreak="break-word"
                  lineHeight="1.1em"
                  mt={1}
                  mb={0.5}
                >
                  {data?.name || "Untitled Activity"}
                </Text>
              </a>
            </Link>

            <HStack spacing={1} lineHeight="1.2em" fontSize={short ? "xs" : "sm"}>
              <Text opacity={doOverlay ? 1 : 0.7} fontWeight="medium">
                {data.sport_type} 𐄁 {plays.length} song{plays.length !== 1 && "s"} incl.{" "}
                {activity.topAritstNames}
              </Text>
            </HStack>
          </Box>
        </Box>
      </Box>

      {short && (
        <>
          <HStack spacing={0} alignItems="stretch" css={{ aspectRatio: "2.3" }}>
            <AlbumGrid plays={playsToShow} highlightedPlayId={highlightedPlayId} />

            {mapUrl ? (
              <Box flex={1.3}>
                <Link href={`/a/${activity.id}`} passHref>
                  <a>
                    <Image
                      w="full"
                      h="full"
                      src={mapUrl}
                      alt="map image of activity"
                      // height={32}
                      objectFit="cover"
                      overflow="hidden"
                    />
                  </a>
                </Link>
              </Box>
            ) : (
              <Box
                flex="1.3"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontSize={22}
                opacity={0.5}
              >
                <Icon as={IoStopwatchOutline} mr={1} title="no map or pictures" />
                <Text fontSize={18}>{data.sport_type}</Text>
              </Box>
            )}
          </HStack>

          <Box textAlign="center">
            <Link href={`/a/${activity.id}`} passHref>
              {ownsActivity && !activity.highlightedPlayId ? (
                <Button as="a" borderTopRadius={0} width="full">
                  🔥 Set your Highlight
                </Button>
              ) : (
                <Button as="a" borderTopRadius={0} width="full">
                  View Activity{" "}
                  {/* <Text pl={1} color="gray.500">
                    {" "}
                    ({plays.length} tracks)
                  </Text> */}
                </Button>
              )}
            </Link>
          </Box>
        </>
      )}

      {!short && (
        <>
          {mapUrl && (
            <Box>
              <Link href={`/a/${activity.id}#embed`} passHref>
                <a>
                  <Image
                    w="full"
                    src={mapUrl}
                    alt="map image of activity"
                    maxH={96}
                    minH={64}
                    objectFit="cover"
                    borderTopLeftRadius={8}
                    borderTopRightRadius={8}
                    borderBottomRightRadius={highlightedPlay ? 0 : 8}
                  />
                </a>
              </Link>
            </Box>
          )}
          {highlightedPlay && (
            <FireBox
              px={[2, 4]}
              pb={[2, 4]}
              pt={1}
              borderBottomRightRadius={8}
              id="highlighted"
              borderTopRadius={mapUrl ? 0 : 8}
            >
              <HStack>
                <WiggleText fontSize={["xl", "2xl"]} zIndex={5} pb={1}>
                  🔥
                </WiggleText>

                <Text
                  fontWeight="black"
                  fontSize={["2xl", "3xl"]}
                  pb={[0, 1]}
                  style={{ WebkitTextStroke: "2px #e1430e" }}
                  opacity={0.8}
                  fontStyle="italic"
                  color="white"
                  letterSpacing={-1}
                  flex={1}
                >
                  HIGHLIGHT
                </Text>

                {ownsActivity && (
                  <Button
                    fontSize={24}
                    size="md"
                    variant="solid"
                    rightIcon={<IoClose />}
                    pr={2}
                    pl={0}
                    position="relative"
                    onClick={async () => {
                      await setHighlight({ set: "remove", playId: null, activityId: activity.id })
                      await activityContext?.refetch()
                    }}
                  ></Button>
                )}
              </HStack>
              <Box bg={background}>
                <Track
                  tracks={[highlightedPlay.spotifyBlob].concat(
                    activity.trackPlays.map((play) => play.spotifyBlob)
                  )}
                  index={0}
                  small={false}
                  trackMetadata={{
                    context: "activity",
                    activityId: activity.id,
                    playId: highlightedPlay.id,
                  }}
                  subtitle={
                    (highlightedPlay.playedAt &&
                      start &&
                      timeDiffString(highlightedPlay.playedAt, start)) ||
                    undefined
                  }
                  canPickHighlight={false}
                />
              </Box>
            </FireBox>
          )}

          {plays && <Plays activity={activity} small={true} />}
        </>
      )}
    </Box>
  )
}
